import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BrandSvg from "../images/undraw_color__schemes_wv48.svg";
import BookingSvg from "../images/undraw_Booking_re_gw4j.svg";
import DeliverySvg from "../images/undraw_location_tracking_46qb.svg";
import { getActiveClasses } from "../utility/get-active-classes";

function IndexPage({ data }) {
  return (
    <Layout hideCreate>
      <SEO
        keywords={[`create online store for fruit and vegetable box`]}
        title="Vegeboxes Create"
        description="Create an online store for fruit and vegetable box delivery"
      />
      <header className="flex flex-col px-12 sm:px-0 sm:mt-20 mt-16 max-w-md mx-auto">
        <h1 className="text-2xl font-bold flex items-center">
          Vegeboxes
          <span className="inline-block bg-green-600 rounded-lg text-white px-2 py-1 ml-2 tracking-wider text-lg">
            CREATE
          </span>
        </h1>
        <p className="sm:text-4xl text-2xl font-bold leading-tight text-gray-600">
          Create an online store for{" "}
          <span className="text-green-600">fruit and vegetable</span> box
          delivery
        </p>
      </header>
      <section
        className="p-4 sm:p-8 rounded-lg mt-32 mx-6 max-w-md sm:mx-auto flex flex-col items-center"
        style={{ backgroundColor: "#f7f7f8" }}
      >
        <h2 className="font-header text-gray-800 text-md sm:text-lg leading-tight mx-auto text-center">
          Vegeboxes Create is coming soon
        </h2>
        <a
          href="http://eepurl.com/hG3_Jz"
          className="bg-blue-500 hover:bg-blue-400 text-white  text-xsm tracking-wide m-1 rounded px-4 py-2 font-bold mt-4"
        >
          Get notified when it arrives
        </a>
      </section>
      <IframeSection
        title={"From the customers perspective"}
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FXiEM0IKyh7xPg7ZKKjwC2k%2FVegeboxes-Hi-fi-designs%3Fnode-id%3D0%253A1"
        href="https://www.figma.com/proto/XiEM0IKyh7xPg7ZKKjwC2k/Vegeboxes-Hi-fi-designs?node-id=1%3A572&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A572"
      />
      <IframeSection
        title={"From the business perspective"}
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FXiEM0IKyh7xPg7ZKKjwC2k%2FVegeboxes-Hi-fi-designs%3Fnode-id%3D401%253A1980"
        href="https://www.figma.com/proto/XiEM0IKyh7xPg7ZKKjwC2k/Vegeboxes-Hi-fi-designs?page-id=401%3A1980&node-id=401%3A1980&viewport=241%2C48%2C0.14&scaling=min-zoom&starting-point-node-id=1%3A1149"
      />
      <Section
        title={"Automatic Delivery Eligibility"}
        description={
          "List the areas that you deliver then only customers that live in those areas will be able to make delivery orders"
        }
        svg={DeliverySvg}
      />
      <Section
        title={"Customer Subscription Management"}
        description={
          "Customers can manage their own subscription to your box. If they want to pause for a week or two, cancel, or change their order they can"
        }
        svg={BookingSvg}
        right
      />
      <Section
        title={"Get up and running quickly"}
        description={
          "Add your branding, list your boxes, setup billing and your done!"
        }
        svg={BrandSvg}
      />
    </Layout>
  );
}

function IframeSection({ title, src, href }) {
  return (
    <div className="mt-32 rounded-lg max-w-6xl mx-auto flex flex-col items-center">
      <h2 className="inline-block text-xl sm:text-2xl text-gray-600 tracking-wider font-bold text-center w-full mb-6">
        {title}
      </h2>
      <iframe
        className="w-full"
        style={{ height: "50vh", minHeight: "500px" }}
        src={src}
        allowfullscreen
      ></iframe>
      <a
        className="bg-blue-500 hover:bg-blue-400 text-white  text-xsm tracking-wide m-1 rounded px-4 py-2 font-bold mt-4"
        href={href}
      >
        Play with the prototype
      </a>
    </div>
  );
}

const Section = ({ title, svg, description, right }) => {
  return (
    <section className="max-w-3xl mx-auto mt-32 flex flex-col items-center px-6">
      <h2 className="inline-block text-xl sm:text-2xl text-gray-600 uppercase tracking-wider font-bold">
        {title}
      </h2>
      <div className={"flex flex-col sm:flex-row mt-8 sm:mt-12 items-center"}>
        <p className="flex-1 text-lg mb-6">{description}</p>
        <img
          src={svg}
          className={getActiveClasses({
            "flex-1 w-full max-w-xs px-12 sm:px-0": true,
            "sm:order-first": !right,
            "ml-16": right,
            "mr-16": !right,
          })}
          alt="Build Website"
        />
      </div>
    </section>
  );
};

export default IndexPage;
